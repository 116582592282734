<template>
<div class="bg-light px-5 py-3 font-family: 'Inter'">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6" >
                   <label @click="back()" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 28px; color: #9E9E9E; "><span class="fas fa-arrow-left"></span> Kembali ke List Vending</label>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right ">
                        <li class="ml-3"><button type="button" class="btn btn-block btn-outline-primary btn-lg p-3" @click="showModal"><span class="fas fa-plus"> </span>  {{ $route.query.isDetail ? "Detail" : "Edit"}}</button></li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
               
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>

     <!-- /.modal -->
        <div class="modal fade" id="slotModal" role="dialog" aria-labelledby="slotModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="save" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="slotModalLabel"> <i class="fa fa-plus"></i> Assign Product</h5>
                </div>
                <div class="modal-body">
                    <div class="row border" v-if="formSlot.product.id">
                          <div class="col-sm-4">
                            <img :src="formSlot.product.image"  width="100%" height="100%" class="img-thumbnail" alt="No Image">
                          </div>
                          <div class="col-sm-6 align-self-center ml-2">
                             <div class="row">
                                <label>{{formSlot.product.sku}}</label>
                              </div>
                              <div class="row">
                                <label>{{formSlot.product.name}}</label>
                              </div>
                          </div>
                          <div class="col-sm-1 align-self-center" v-on:click="clearProduct" v-if="!$route.query.isDetail"> <label class="text-blue">X</label></div>
                    </div>
                    <div class="form-group" v-else >
                        <label for="recipient-price" class="col-form-label">Produk<span class="text-red">*</span></label>
                        <select2 v-model="formSlot.product.id" required :config="productConfig" class="form-control"  v-on:change="signalChange" :disabled="loading || $route.query.isDetail">
                            <option :value="formSlot.product.id" selected v-if="formSlot.product.id && this.$route.query.id">{{ formSlot.product.name }}</option>
                        </select2>
                    </div>
                    
                    <div class="form-group">
                        <label for="recipient-price" class="col-form-label">Harga<span class="text-red">*</span></label>
                        <input type="number" required oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-price" v-model="formSlot.price" :disabled="loading || $route.query.isDetail">
                    </div>
                        <div class="form-group">
                        <label for="recipient-stock" class="col-form-label">Quantity<span class="text-red">*</span></label>
                        <input type="number" required oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-stock" v-model="formSlot.stock" :disabled="loading || $route.query.isDetail">
                    </div>       
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetSlot">Close</button>
                    <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Delete</button> -->
                    <div v-if="!$route.query.isDetail">
                      <button type="submit" class="btn btn-primary" :disabled="loading">
                         <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                         {{ loading ? 'Saving ...' : 'Save' }}
                     </button>
                    </div>
                </div>
                </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="updateVending" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ $route.query.isDetail ? "Detail Vending" : "Edit Vending"}}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Vending Name<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-name" required v-model="formVending.name" :disabled="loading || $route.query.isDetail">
                    </div>
                    <div class="form-group">
                        <label for="recipient-vending-type" class="col-form-label">Vending Type<span class="text-red">*</span></label>
                        <select2 v-model="formVending.vending_type.id" required :config="vendingTypeConfig" class="form-control" :disabled="loading || $route.query.isDetail">
                            <option :value="formVending.vending_type.id">{{ formVending.vending_type.type_name }}</option>
                        </select2>
                    </div>
                    <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Merchant Name<span class="text-red">*</span></label>
                        <select2 v-model="formVending.merchant.id" required :config="merchantConfig" class="form-control" :disabled="loading || $route.query.isDetail">
                            <option :value="formVending.merchant.id">{{ formVending.merchant.name }}</option>
                        </select2>
                    </div>
                    <div class="form-group">
                        <label for="recipient-vending_id" class="col-form-label">ID Vending<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-vending_id" required v-model="formVending.vending_id" disabled>
                    </div>
                    <div class="form-group">
                        <label for="recipient-address" class="col-form-label">Address<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-address" required v-model="formVending.address" :disabled="loading || $route.query.isDetail">
                    </div>  
                    <div class="form-group">
                        <label for="recipient-row_slot" class="col-form-label">Baris Slot<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-row_slot" required v-model="formVending.row_slot" :disabled="loading || $route.query.isDetail">
                    </div>   
                    <div class="form-group">
                        <label for="recipient-col_slot" class="col-form-label">Kolom Slot<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-col_slot" required v-model="formVending.col_slot" :disabled="loading || $route.query.isDetail">
                    </div>        
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button v-if="!$route.query.isDetail" type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>

        <ListGame :games="games" :saveListGames="saveListGames" :formGames="formGames" :isDetail="$route.query.isDetail"></ListGame>

      <div class="container-fluid">
          <div class="row mb-2">
              <div class="col-sm-4">
                  <div class="box1 m-2">
                      <div class="inner" v-if="formVending">
                        <p class="label1" >{{formVending.name}}</p>
                        <p class="label2" >{{formVending.merchant.name}} </p>
                        <p class="label3" >{{formVending.vending_id}}</p>
                        <p class="label3" >{{formVending.address}}</p>
                      </div>
                  </div>
              </div><!-- /.col -->
              <div class="col-sm-8">
                  <ol class="breadcrumb float-sm-right bg-light ">
                      <li class="mr-4">
                          <div class="box1 m-2">
                              <div class="inner" v-if="count">
                                <p class="label4">Jumlah Transaksi</p>
                                <h3 >{{count.curren_count}}</h3>
                                    <p v-if="count.status_value=='naik'"> <span style="color:#3267E3" > <i class="fas fa-arrow-up"></i> {{count.percentage_count}}% </span> from last month</p>
                                    <p v-if="count.status_value=='turun'"> <span style="color:red"> <i class="fas fa-arrow-down"></i> {{count.percentage_count}}% </span> from last month</p>
                              </div>
                          </div>
                      </li>
                      <li >
                          <div class="box1 m-2">
                              <div class="inner" v-if="count">
                                <p class="label4">Nilai Transaksi</p>
                                <h3 >Rp {{formatPrice(count.curren_value)}}</h3>
                                    <p v-if="count.status_count=='naik'"> <span style="color:#3267E3"> <i class="fas fa-arrow-up"></i> {{count.percentage_value}}% </span> from last month</p>
                                    <p v-if="count.status_count=='turun'"> <span style="color:red"> <i class="fas fa-arrow-down"></i> {{count.percentage_value}}% </span> from last month</p>
                              </div>
                          </div>
                      </li>
                  </ol>
              </div><!-- /.col -->
          </div><!-- /.row -->
      </div><!-- /.container-fluid -->
      
      <!-- for games -->
      <div class="card card-games">
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-4" style="margin-left: 20px;">
            <img src="@/assets/img/ion_game-controller.png">
            <span>Status Games:</span>
            <span v-if="activeGames" style="color: #43936C; font-size: 14px; font-weight: 700;"> Already active</span>
            <span v-else style="color: #CB3A31; font-size: 14px; font-weight: 700;"> Not Active</span>
          </div>
          <div class="col-md-7">
            <button type="button" class="btn btn-primary float-md-right" @click="showModalGames"><img src="@/assets/img/setting-logo.png">Setting</button>
          </div>
        </div>
      </div>

      <div class="container-fluid" >
          <div class="card " v-for="(row, rindex) in listSlot2" :key="'row-' + rindex" >
            <div class="row p-2">
              <div class="col m-2 " v-for="(col, cindex) in row" :key="'col-' + cindex" style="text-align: center; align-items: center;" :id="col.id">
                <p class="label5" style="color: #000000;" >{{ col.slot_number }}</p >
                <div class="bg-light border border-light" style="width:100%;">
                  <img :src="col.product_image || 'Placeholder.jpg'" style=" width: 124px; height: 150px; cursor: pointer;" @click="btnAddUpdate(col)"/>
                  <div class="pb-2" style="width:100%; background: #F5F5F5; bottom:0;">
                    <div class="pb-1"> 
                      <!-- <div :style="{ 'background-color': col.stock <= 5 && col.id !== null? '#CD7B2E' : '#FFFFFF', 'color': 'white' }">
                        <p><i class="fas fa-exclamation-triangle"></i>Slot Rendah</p>
                      </div> -->
                      <p> Rp. {{ col.price || 0 }}</p>
                      <p>{{ col.product_name || '-' }}</p>
                      <input type="text" v-model="col.stock" class="input-stock" :disabled="col.id == null || loading" :style="col.stock > maxSlotCapacity ? 'border: 2px solid red': null"> / {{ maxSlotCapacity }}
                    </div>

                    <div class="pb-3">
                      <button type="button" class="btn btn-primary btn-sm" :disabled="col.id == null || loading" @click="saveStock(col)">Update stock</button>
                    </div>
                    
                    <label class="switch">
                      <input type="checkbox" v-if="col.id" @click="saveActiveSlot(col)" v-model="col.is_active">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>       
            </div>
          </div>

       <!-- <div class="container-fluid" >
          <div class="card " v-for="i in dataparams.row" :key="i" >
            <div class="row p-2">
                <div class="col m-2 " v-for="x in dataparams.col" :key="x" style="text-align: center; align-items: center;" v-bind:id="`div`+((i-1)*dataparams.col+x)+``" >
                    <input v-if="listSlot[`${(i-1)*dataparams.col+x}`]" type="text" v-bind:id="`id`+((i-1)*dataparams.col+x)+``" :value="listSlot[`${(i-1)*dataparams.col+x}`].id || 0" hidden>
                    <p class="label5" style="color: #000000;" >{{(i-1)*dataparams.col+x}}</p >
                      <div class="bg-light border border-light " style="width:100%;">
                        <img v-if="listSlot[`${(i-1)*dataparams.col+x}`]" :src="listSlot[`${(i-1)*dataparams.col+x}`].product.image" style=" width: 124px; height: 150px;" @click="btnAddUpdate((i-1)*dataparams.col+x)" />
                        <img v-else src="@/assets/img/Placeholder.jpg" style=" width: 124px; height: 150px;" @click="btnAddUpdate((i-1)*dataparams.col+x)" />
                        <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                        <p v-if="listSlot[`${(i-1)*dataparams.col+x}`]">Rp {{ listSlot[`${(i-1)*dataparams.col+x}`].price }} </p>
                        <div v-if="listSlot[`${(i-1)*dataparams.col+x}`]" >
                          <input type="text" :disabled="$route.params.isDetail" v-model="listSlot[`${(i-1)*dataparams.col+x}`].stock" class="input-stock" @blur="saveStock($event, listSlot[`${(i-1)*dataparams.col+x}`])"> / 11
                        </div>
                        <div v-else>
                          <p> 0 / 11 </p>
                        </div>
                        <label class="switch">
                            <input type="checkbox" v-if="listSlot[`${(i-1)*dataparams.col+x}`]" @click="saveActiveSlot(listSlot[`${(i-1)*dataparams.col+x}`])" v-model="listSlot[`${(i-1)*dataparams.col+x}`].is_active">
                            <div class="slider round"></div>
                        </label>
                    </div>
                    </div>
                </div>       
            </div>
          </div>
       
       </div> -->
       
    </div>
</div>
</template>
<script>
const {console} = window
import axios from "axios"
import ListGame from "@components/ListGame.vue"

export default {
  components: {
    ListGame
  },
  data(){
    return{
      maxSlotCapacity: 11,
      slotList : null,
      error: undefined,
      loading: false,
      vending:null,
      count:null,
      id:null,
      vending_id:null,
      productConfig: {}, 
      merchantConfig: {},
      vendingTypeConfig: {},
      dataparams:{
        row:5,
        col:8
      },
      formSlot:{
          id: null,
          name:"",
          product:{
            id: null,
            name:null,
            image:null,
            sku:null
          },
          vending:{
            id: null
          },
          price:0,
          stock:0,
          is_active: true
      }, 
      formVending:{
        id: null,
        vending_id:"",
        name:"",
        address:"",
        row_slot:null,
        col_slot:null,
        vending_type: {
          id: null
        },
        merchant:{
            id : null
        }
      },
      games: [],
      formGames : [],
      listSlot: {},
      listSlot2: [],
      activeGames: null
    }

  },
  async mounted() {
    if (!this.$route.query.vendingId)
      return


    this.vending_id = this.$route.query.vendingId;
    try {
      await this.getDataVending()
      await this.getData()

      this.initData()
      this.getDataCount()
      this.getDataMerchant()
      this.getDataActiveGame()
      // this.getDataCount()
    } catch (error) {
      console.log(error)
    }
  },
  methods : {
    initData() {
      this.productConfig = {
          ajax: {
              url: env.api.base + '/product?sort=id',
              data: params => {
                  var filters = [
                      ["name", "like", params.term]
                  ]
                  var query = {
                      sort: "name",
                      filters: params.term ? JSON.stringify(filters) : "",
                      page: params.page ? params.page : 1
                  }

                  return query
              },
              processResults: (data, params) => {
                  data.content.forEach(item => {
                      item.text = item.name
                  })
                  return {
                      results: data.content,
                      pagination: {
                          more: data.last == false
                      }
                  }
              }
          }
      },
      this.vendingTypeConfig = {
          ajax: {
              url: env.api.base + '/vendingType?sort=id',
              data: params => {
                  var filters = [
                      ["type_name", "like", params.term]
                  ]
                  var query = {
                      sort: "type_name",
                      filters: params.term ? JSON.stringify(filters) : "",
                      page: params.page ? params.page : 1
                  }

                  return query
              },
              processResults: (data, params) => {
                  data.content.forEach(item => {
                      item.text = item.type_name
                  })
                  return {
                      results: data.content,
                      pagination: {
                          more: data.last == false
                      }
                  }
              }
          }
      }
    },
    async getData(){
      const res = await this.Api.get(`/slot?size=100&filters=[["vending.id","=","${ this.vending_id }"]]`)
      const data = res.data.content
      let number = 0;
      for (let i = 0; i < this.formVending.row_slot; i++) {
        let coltemp = []
        for (let j = 0; j < this.formVending.col_slot; j++) {
          number++;
          const idx = data.findIndex(t => parseInt(t.name) === number)
          coltemp.push({
            id: idx > -1 ? parseInt(data[idx].id) : null,
            slot_number: number,
            product_name: idx > -1 ? data[idx].product.name : null,
            product_image: idx > -1 ? data[idx].product.image : null,
            product_sku: idx > -1 ? data[idx].product.sku : null,
            product_id: idx > -1 ? data[idx].product.id : null,
            stock: idx > -1 ? data[idx].stock : 0,
            is_active: idx > -1 ? data[idx].is_active : false,
            price: idx > -1 ? data[idx].price : 0
          })   
         
        }
        this.listSlot2.push(coltemp)
      }
    },
    async getDataVending(){
      try {
        const res = await this.Api.get(`/vending/${this.$route.query.vendingId}`)
        this.formVending = {...res.data}
        this.formVending.vending_type = res.data.vending_type || {}
        this.formVending.row_slot = res.data.row_slot || 5
        this.formVending.col_slot = res.data.col_slot || 8
        
      } catch (error) {
        console.log(error)
        
      }
    },
    async getDataCount(){
      try {
        const res = await this.Api.get(`/transaction/countByVending/${this.$route.query.vendingId}`)
        this.count = res.data
      } catch (error) {
        console.log(error)
      }
    },
    getDataMerchant(){
      this.merchantConfig = {
                ajax: {
                    url: env.api.base + '/merchant?sort=id',
                    data: params => {
                        var filters = [
                            ["name", "like", params.term]
                        ]
                        var query = {
                            sort: "name",
                            filters: params.term ? JSON.stringify(filters) : "",
                            page: params.page ? params.page : 1
                        }

                        return query
                    },
                    processResults: (data, params) => {
                        data.content.forEach(item => {
                            item.text = item.name
                        })
                        return {
                            results: data.content,
                            pagination: {
                                more: data.last == false
                            }
                        }
                    }
                }
            }
    },
    signalChange(){
          this.Api.get(`/product/${this.formSlot.product.id}`).then(res => {
                this.formSlot.product = res.data
            }).catch((e)  => {
                console.log(e)
            })
    },
    clearProduct(){
          this.formSlot.product.id=null
          this.formSlot.product.image=null
          this.formSlot.product.name=null
          this.formSlot.product.sku=null
    },
    showModal() {
          this.getDataVending()
          $("#exampleModal").modal("show");
    },
    updateVending() {
      this.loading = true
      this.Api.put(`/vending/${this.formVending.id}`, this.formVending).then(res => {
        this.loading = false
        $('#exampleModal').modal('hide');
      }).catch((e)  => {
        this.loading = false
        this.error = e.response.data.message
        this.text_validation = "Submit Failed";      
      })
    },
    formatPrice(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    resetSlot(value) {
      this.formSlot = {
          id: null,
          name:value,
          product:{
            id: null
          },
          vending:{
            id: null
          },
          price:0,
          stock:0,
          is_active: true
      }
      
      $("#slotModal").modal("hide");
    },
    async btnAddUpdate(value){
      this.resetSlot(value.slot_number)
      
      try {
        if (value?.id) {
          const res = await this.Api.get('/slot/'+ value?.id)
          this.formSlot = res.data
        }
        $("#slotModal").modal("show");
      } catch (error) {
        console.log(error) 
      }
    },
    refresh() {
      $router.push('/')
    },
    async save() {
      this.formSlot.vending.id = this.formVending.id
      this.formSlot.stock = parseInt(this.formSlot.stock)
      // console.log(this.formSlot)
      let res
      try {
        if(this.formSlot.stock > this.maxSlotCapacity) {
          return alert(`Failed to update slot!\nnew stock (${this.formSlot.stock}) is more than maximum slot capacity (${this.maxSlotCapacity}) !`)
        }

        if(this.formSlot.id !== null) 
          await this.Api.put(`/slot/${this.formSlot.id}`, this.formSlot)
        else 
          res =  await this.Api.post(`/slot`, this.formSlot)

          for (let i = 0; i < this.formVending.row_slot; i++) {
            const idx = this.listSlot2[i].findIndex(t => t.slot_number === parseInt(this.formSlot.name))
            if (idx > -1) {
              this.listSlot2[i][idx] = {
                id: this.formSlot.id !== null ? this.formSlot.id : res.data.id,
                product_name: this.formSlot.product.name,
                product_id: this.formSlot.product.id,
                product_image: this.formSlot.product.image,
                product_sku: this.formSlot.product.sku,
                stock: parseInt(this.formSlot.stock),
                slot_number: parseInt(this.formSlot.name),
                is_active: this.formSlot.is_active,
                price: this.formSlot.price
              }
            }
          }
          
          this.resetSlot()
          $('#slotModal').modal('hide');

          alert('Slot updated successfully!')
      } catch (error) {
        alert(`Failed update slot, ${error}`)
      }
    },
    back(){
      this.$router.replace({name: 'Vending'})
    },
    showModalGames() {
      if(this.vending_id) {
        this.Api.get(`/vendingGame/list/${this.vending_id}`).then(res => {
          this.games = res.data.content
          for (let i = 0; i < this.games.length; i++) {
            this.formGames.push({
              status: this.games[i].status,
              is_active: this.games[i].is_active,
              vending_id: this.vending_id,
              game_id: this.games[i].id
            })
          }
          $("#listGameModal").modal("show");
        }).catch((e) => {
          window.console.log(e, "error")
        })
      }
    },
    getDataActiveGame() {
       this.Api.get(`/vendingGame/list/${this.vending_id}`).then(res => {
        this.activeGames = res.data.content.find((data) => data.is_active === true)
       }).catch((e) => window.console.log(e))
    },

    saveListGames() {
      let gameActive = this.formGames.findIndex((d) => d.is_active === true)
        this.Api.post(`/vendingGame`, this.formGames[gameActive]).then(res => {
        this.loading = false
        $("#listGameModal").modal("hide");
        this.getDataActiveGame();
      }).catch((e) => {})
    },
    async saveStock(col) {
      this.loading = true

      let newStock = col.stock
      if(newStock > this.maxSlotCapacity) {
        this.loading = false
        return alert(`Failed to update stock!\nnew stock (${newStock}) is more than maximum slot capacity (${this.maxSlotCapacity}) !`)
      }

      try {
        await this.Api.put(`/slot/${col.id}`, {
          id: col.id,
          stock: col.stock,
          name: col.slot_number,
          price: col.price,
          is_active: col.is_active,
          product: {
            id: col.product_id,
            name: col.product_name,
            sku: col.product_sku,
            image: col.image
          },
          vending: {
            id: this.vending_id
          }
        })
        alert('Stock updated successfully!')
      } catch (error) {
        alert(`Failed to update stock!\n${error}`)
      }

      this.loading = false
    },
    async saveActiveSlot(col) {
      col.is_active = !col.is_active
      this.$emit('setCheckboxVal', this.formSlot.is_active)

      try {
        await this.Api.put(`/slot/${col.id}`, {
          id: col.id,
            stock: col.stock,
            name: col.slot_number,
            price: col.price,
            is_active: col.is_active,
            product: {
              id: col.product_id,
              name: col.product_name,
              sku: col.product_sku,
              image: col.image
            },
            vending: {
              id: this.vending_id
            }
        })
        alert('Slot updated successfully!')
      } catch (error) {
        alert(`Failed to update slot!\n${error}`)
      }
    }
  }
}

</script>
<style>
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 8px;
        width: 360px;
        height: 190px;
        left: 64px;
        top: 132px;
        background: #FFFFFF;
        border-radius: 12px; 
    }
    .label1{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #0A0A0A;
    }
    .label2{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #404040;
    }
    .label3{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #404040;
    }
    .label4{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #32434A;

    }
    .balel5{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }
    .borderDiv{
      border: 1px solid #C2C2C2;
    }
    .notActive{
        color : red;
    }
</style>