<template>
    <!-- modal -->
    <!-- <div> -->
        <div class="modal fade" id="listGameModal" tabindex="-1" role="dialog" aria-labelledby="listGameModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width:790px;">
                <form @submit.prevent="saveListGames" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="listGameModalLabel"> <img src="@/assets/img/ion_game-controller-white.png"> Choose a Game</h5>
                        </div>
                        <div class="modal-body">
                           <!-- card list games -->
                            <div class="modal-games">
                                <div v-for="(game, index ) in games" :key="index" style="margin: 8px;">
                                <div v-show="game.status === true" class="card" style="width: 240px; height: 250px; margin-left: 15px; border-radius: 10px;">
                                    <div class="row" style="margin: 0">
                                        <img :src="game.game_thumbnail" width="100%" height="150px" style="border-radius: 10px 10px 0 0; object-fit: contain;">
                                        <div class="col-sm-6" style="margin: 10px 0 10px 10px;">
                                            <span style="font-size: 14px; font-weight: 700;">{{ game.game_title }}</span>
                                        </div>
                                    
                                        <div class="col-sm-4" style="margin:10px;">
                                            <label class="switch">
                                                <input type="checkbox" v-model="formGames[index].is_active" :disabled="isDetail">
                                                <div class="slider round"></div>
                                            </label>
                                        </div>
                                        <hr class="solid">
                                        <button class="button-setting-game">Setting Game</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                            <!-- </div> -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" v-if="!isDetail" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Creating ...' : 'Create' }}
                                </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    <!-- </div> -->

</template>

<script>

export default {
    props: ["games", "saveListGames", "formGames", "isDetail"],
    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            error: undefined,
            loading: false,
            text_validation : null,
            maxSize: 1024,
            selectedPaymentGateway: null,
            disabled: false,
        }
    },
}

</script>